//-==================================================================
//-                            page.js
//-=================================================================
// 【読込】「gulp-file-include」で外部ファイルをインクルードできるようにした
// 【注意！】アットマークを書くだけでおこられる。たとえ全角でも
// 【注意！】 コメントアウトできない。「//」をつけてもファイルが読込まれてしまう
// 【注意！】圧縮された「minファイル」では、うまくいかない！
//-=================================================================

// ---------------------------------------------------------------------------------
//           PC/SP 切替え
// ---------------------------------------------------------------------------------
$(function(){
	// Window幅
	var winW = $(window).width();


	// -----------------------------------------------
	// javaScript
	// -----------------------------------------------
	var jsPC = document.createElement("script");
	var jsPagePC = document.createElement("script");
	var jsSP = document.createElement("script");
	var jsPageSP = document.createElement("script");
	jsPC.type = "text/javascript";
	jsPagePC.type = "text/javascript";
	jsSP.type = "text/javascript";
	jsPageSP.type = "text/javascript";

	jsPC.src = "../JS/commonPC.min.js"; // pc向け
	jsPagePC.src = "./js/pagePC.min.js"; // pc向け
	jsSP.src = "../JS/commonSP.min.js"; // sp向け
	jsPageSP.src = "./js/pageSP.min.js"; // sp向け

	// -----------------------------------------------
	// CSS
	// -----------------------------------------------
	var cssPC = document.createElement("link");
	var cssSP = document.createElement("link");
	var cssPagePC = document.createElement("link");
	var cssPageSP = document.createElement("link");

	cssPC.setAttribute("rel", "stylesheet");
	cssSP.setAttribute("rel", "stylesheet");
	cssPagePC.setAttribute("rel", "stylesheet");
	cssPageSP.setAttribute("rel", "stylesheet");

	cssPC.setAttribute("type", "text/css");
	cssSP.setAttribute("type", "text/css");
	cssPagePC.setAttribute("type", "text/css");
	cssPageSP.setAttribute("type", "text/css");

	cssPC.href = "../CSS/indexPC.min.css"; // pc
	cssSP.href = "../CSS/indexSP.min.css"; // sp
	cssPagePC.href = "./css/pagePC.min.css"; // pc
	cssPageSP.href = "./css/pageSP.min.css"; // sp


	// -----------------------------------------------------------------------
	// windowサイズで切替
	// -----------------------------------------------------------------------


	// ウィンドウサイズ = 750px以下
	if (winW < 750) {
		//console.log('sp'+winW);
		document.getElementsByTagName("head")[0].appendChild(cssSP);
		document.getElementsByTagName("head")[0].appendChild(cssPageSP);
		document.getElementsByTagName("body")[0].appendChild(jsSP);
		document.getElementsByTagName("body")[0].appendChild(jsPageSP);
		// ウィンドウサイズ = 751px以上
	} else if (winW >= 750) {
		console.log('pc'+winW);
		document.getElementsByTagName("head")[0].appendChild(cssPC);
		document.getElementsByTagName("head")[0].appendChild(cssPagePC);
		document.getElementsByTagName("body")[0].appendChild(jsPC);
		document.getElementsByTagName("body")[0].appendChild(jsPagePC);
	}

	// -----------------------------------------------------------------------
	// リサイズ時
	// -----------------------------------------------------------------------
	var timer = false;
	var currentWidth = 0;
	var type = "";
	// PC or SP
	if (winW >= 750) {
		type = "PC";
	} else {
		type = "SP";
	}

	$(window).resize(function() {
		// console.log("resize!");
			// -----------------------------------------------------------------------
			// iOS Safariでスクロールしただけでリサイズイベントが発生する対処法
			if (currentWidth == window.innerWidth) {
				// ウインドウ横幅が変わっていないため処理をキャンセル。
				return;
			}
			currentWidth = window.innerWidth;
			// -----------------------------------------------------------------------
			// タイマーリセット
			if (timer !== false) {
					clearTimeout(timer);
			}
			// タイマー 200ms
			timer = setTimeout(function() {
					winW = $(window).width();
					// -----------------------------------------------------------------------
					// PC表示中
					if (type == "PC") {
						// 750px以下になったら
						if (winW < 750) {
								document.getElementsByTagName("head")[0].appendChild(cssSP);
								document.getElementsByTagName("body")[0].appendChild(cssPageSP);
								document.getElementsByTagName("body")[0].appendChild(jsSP);
								document.getElementsByTagName("body")[0].appendChild(jsPageSP);
								location.reload();
						}
					}
					// ---------------------------------------------------------------
					// SP表示中
					if (type == "SP") {
						// ウィンドウサイズ = 750px以上になったら
						if (winW >= 750) {
								document.getElementsByTagName("head")[0].appendChild(cssPC);
								document.getElementsByTagName("head")[0].appendChild(cssPagePC);
								document.getElementsByTagName("body")[0].appendChild(jsPC);
								document.getElementsByTagName("body")[0].appendChild(jsPagePC);
								location.reload();
						}
					}
			}, 200);
			// -----------------------------------------------------------------------
	});
});
